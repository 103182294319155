<template>
  <!-- 發布至Line Notify的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="postToLineNotifyModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">
            發布至Line Notify
            <a
              href="https://youtu.be/xtK_quJZI0g?si=6nr_BiKTdhpxhH1v"
              target="_blank"
            >
              <img class="info-icon" src="@/assets/icon/interrogation.png" />
            </a>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="closeModal"
          ></button>
        </div>
        <div class="modal-body">
          <SelectInfos :propsSelectInfos="propsSelectInfos"></SelectInfos>
          <!-- 發布內容 -->
          <div>
            <p class="tw-border-start fw-bolder mb-3">發布內容</p>
            <!-- 發布下單連結至Line Notify -->
            <div class="form-check p-0">
              <input
                class="form-check-input m-0 me-2"
                type="radio"
                name="selectContent"
                id="HasNotDescription"
                value="hasNotDescription"
                v-model="postToLineNotify.showType"
              />
              <label class="form-check-label" for="HasNotDescription"
                >發布下單連結至Line Notify</label
              >
            </div>
            <!-- 發布下單連結 + 商品描述至Line Notify -->
            <div class="form-check p-0">
              <input
                class="form-check-input m-0 me-2"
                type="radio"
                name="selectContent"
                id="HasDescription"
                value="hasDescription"
                v-model="postToLineNotify.showType"
              />
              <label class="form-check-label" for="HasDescription"
                >發布下單連結 + 商品描述至Line Notify</label
              >
            </div>
            <!-- 僅商品列表 -->
            <div class="form-check p-0">
              <input
                class="form-check-input m-0 me-2"
                type="radio"
                name="selectContent"
                id="OnlyMerchandises"
                value="onlyMerchandises"
                v-model="postToLineNotify.showType"
              />
              <label class="form-check-label" for="OnlyMerchandises"
                >僅商品列表</label
              >
            </div>
          </div>
          <hr />
          <!-- 內容 -->
          <div>
            <p class="tw-border-start fw-bolder mb-3">內容</p>
            <div
              class="mb-2"
              v-for="(data, index) in postToLineNotify.finalMessages"
              :key="data.id"
            >
              <div class="row mb-1">
                <p class="fw-bolder col-10 p-0">{{ data.name }}</p>
                <div class="col-2 p-0">
                  <!-- <button
                    class="tw-btn"
                    :class="{
                      'tw-btn-success': data.placeOnOrderUrlPosition === 'd4',
                      'tw-btn-danger':
                        data.placeOnOrderUrlPosition === 'bottom',
                    }"
                    v-if="postToLineNotify.showType === 'hasDescription'"
                    @click="changePlaceOnOrderUrlPosition(data, index)"
                  >
                    {{
                      data.placeOnOrderUrlPosition === "d4" ? "預設" : "最下面"
                    }}
                  </button> -->
                </div>
              </div>
              <textarea
                cols="30"
                rows="10"
                class="form-control"
                v-model="data.description"
              ></textarea>
            </div>
          </div>
          <!-- 自定義訊息 -->
          <div>
            <p class="tw-border-start fw-bolder mb-3">自定義訊息(1000字)</p>
            <textarea
              cols="30"
              rows="10"
              class="form-control"
              maxlength="1000"
              v-model="postToLineNotify.setOwnMessage"
            ></textarea>
          </div>
          <hr />
          <!-- 選擇發送對象 -->
          <div>
            <p class="tw-border-start fw-bolder mb-3">選擇發送對象</p>
            <div>
              <!-- 一對一 -->
              <div
                class="form-check p-0"
                v-if="userInfo && userInfo.userLineLink.notifyAccessToken"
              >
                <input
                  class="form-check-input m-0 me-2"
                  type="checkbox"
                  value="mySelf"
                  id="SendToMyself"
                  v-model="postToLineNotify.selectNotify"
                />
                <label class="form-check-label" for="SendToMyself">
                  一對一(僅限賣家自己)
                </label>
              </div>
              <p v-else class="text-danger fw-bolder mb-2">
                您尚未在系統綁定Line Notify!
              </p>
              <template v-if="storeLineNotify.bindStore.length > 0">
                <div
                  class="form-check p-0"
                  v-for="data in storeLineNotify.bindStore"
                  :key="data.id"
                >
                  <input
                    class="form-check-input m-0 me-2"
                    type="checkbox"
                    :value="data.id"
                    :id="`SendToGroup${data.id}`"
                    v-model="postToLineNotify.selectNotify"
                  />
                  <label
                    class="form-check-label"
                    :for="`SendToGroup${data.id}`"
                  >
                    {{ data.groupName }}
                  </label>
                </div>
              </template>

              <p v-else class="text-danger fw-bolder mb-2">
                您尚未在賣場綁定Line Notify群組!
              </p>
              <!-- 群組 -->
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
            @click="closeModal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="postToNotify"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// mixin
import { SplitGraph } from '../../methods/mixins/splitGraph'
// methos
import { handleImageHash2Url } from "@/methods/mixins/imageHash2Url.js"

export default {
  props: {
    storeLineNotify: {
      type: Object,
      default: {
        loading: false,
        bindStore: [],
        bindMyself: null
      }
    },
    propsSelectInfos: {
      type: Object,
      default: {
        show: 'name',
        data: []
      }
    },
    showLineNotifyModal: {
      type: Boolean,
      default: false
    }
  },
  emits: ['notifyParentFromPostToLineNotify'],
  mixins: [SplitGraph],
  data() {
    return {
      postToLineNotifyModal: {},
      postToLineNotify: {
        showType: 'hasNotDescription', // hasNotDescription: 沒描述，hasDescription: 有描述，onlyMerchandises: 商品列表
        setOwnMessage: '',
        finalMessages: [],
        selectNotify: [],
      },
    }
  },
  mounted() {
    this.createModals(['postToLineNotifyModal'])
  },
  computed: {
    placeOnOrderUrlPosition() {
      let placeOnOrderUrlPosition = null
      this.$store.state.Prefer.p_line.placeOnOrderUrlPosition.forEach(item => {
        if (item.selected) placeOnOrderUrlPosition = item.value
      })
      return placeOnOrderUrlPosition
    },
    // 取當前使用者所在賣場資訊，透過 paticipantId 在一對一時傳 notify 給自己（僅限:賣場主／小幫手）
    CurrentParticipant() {
      return this.$store.state.CurrentParticipant.participantInfo
    },
    // 取當前使用者資訊，用來判斷當前使用者是否有綁定 Line Notify
    userInfo() {
      return this.$store.state.StoreList.userInfo
    },
  },
  watch: {
    showLineNotifyModal(val) {
      if (val) {
        this.postToLineNotify = {
          showType: 'hasNotDescription',
          setOwnMessage: '',
          finalMessages: [],
          selectNotify: [],
        }
        // 整理資料
        this.sortoutData()
        this.postToLineNotifyModal.show()
      } else {
        this.postToLineNotifyModal.hide()
      }
    },
    'postToLineNotify.showType'() {
      // 整理資料
      this.sortoutData()
    },
  },
  methods: {
    // 關閉 modal
    closeModal() {
      this.$emit('notifyParentFromPostToLineNotify', {
        showLineNotifyModal: false,
        clearSelectItems: false
      })
    },
    // 整理資料
    sortoutData() {
      this.postToLineNotify.finalMessages = []
        if (this.postToLineNotify.showType !== 'onlyMerchandises') {
          this.propsSelectInfos.data.forEach((item) => {
            let object = {
              id: item.id,
              name: item.name,
              description: '',
              rules: '',
              imgUrl: '',
              placeOnOrderUrlPosition: this.placeOnOrderUrlPosition, // d4: 預設位置，bottom: 在最底下
              merchandiseInfo: item,
            }
            this.postToLineNotify.finalMessages.push(this.combineMerchInfo(object))
          })
        } else {
          let object = {
            id: new Date() * 1,
            name: null,
            description: '',
            rules: '',
            imgUrl: '',
            placeOnOrderUrlPosition: this.placeOnOrderUrlPosition, // d4: 預設位置，bottom: 在最底下
            merchandiseInfo: null,
          }
          this.propsSelectInfos.data.forEach((item, index) => {
            object.description += `${index + 1}. ${item.name}\n`
          })
          this.postToLineNotify.finalMessages.push(object)
        }
    },
    // 組合商品描述與款式規則
    combineMerchInfo(object) {
      // 組資訊
      let finalObj = null
      // 商品名稱
      object.description += `<${object.merchandiseInfo.name}>\n`
      // 下單連結
      const placeOnOrderUrl = `${process.env.VUE_APP_ORIGINURL}/#/customer/store/${this.$route.params.storeId}/orderPage?merchandiseId=${object.merchandiseInfo.id}`
      if (object.placeOnOrderUrlPosition === 'd4') object.description += `下單連結: ${placeOnOrderUrl}\n`
      // 收單時間
      if (object.merchandiseInfo.deadLineTime) object.description += `收單時間: ${this.$methods.moment(object.merchandiseInfo.deadLineTime).format("YYYY-MM-DD HH:mm:ss")}\n`
      // 到貨時間
      if (object.merchandiseInfo.arrivalTime) object.description += `到貨時間: ${this.$methods.moment(object.merchandiseInfo.arrivalTime).format("YYYY-MM-DD HH:mm:ss")}\n`
      // 成團數
      if (object.merchandiseInfo.startSaleQuantity) object.description += `成團數: ${object.merchandiseInfo.startSaleQuantity}人\n`
      // 描述
      let description = ''
      if (object.merchandiseInfo.description) description += `商品描述: \n${object.merchandiseInfo.description}\n\n`

      // 發佈到 line notify (要先判斷有沒有選到加入描述)
      if (this.postToLineNotify.showType === 'hasDescription') object.description += description
      
      // 組款式規則
      console.log(object.merchandiseInfo.merchandiseStyles)
      let mainStyles = '[主要款式]\n'
      let oneStyles = '[細項一]\n\t'
      let twoStyles = '[細項二]\n\t'
      let threeStyles = '[細項三]\n\t'
      let fourStyles = '[細項四]\n\t'
      let currentHierarchy = 0
      object.merchandiseInfo.merchandiseStyles.forEach(style => {
        if (style.hierarchy === 0 && !style.deleted) {
          mainStyles += `\t${style.name}(單價NT$${style.price}元)\n`
          if (style.merchandiseDiscounts.length > 0) {
            style.merchandiseDiscounts.forEach(discount => {
              // 判斷數量
              // if (discount.minQuantity && discount.maxQuantity) {
              //   mainStyles += `\t\t買${discount.minQuantity}~${discount.maxQuantity}個，單價NT$${discount.price}`
              // } else if (discount.minQuantity && !discount.maxQuantity) {
              //   mainStyles += `\t\t買${discount.minQuantity}個以上，單價NT$${discount.price}`
              // }
              // 判斷 VIP
              // if (discount.storeVipId) mainStyles += `(限制VIP: ${discount.storeVip.name}含以上)\n`
              // else mainStyles += '\n'
            })
          }
          currentHierarchy = style.hierarchy
        } else if (style.hierarchy === 1 && !style.deleted) {
          currentHierarchy = style.hierarchy
          oneStyles += `${style.name} `
        } else if (style.hierarchy === 2 && !style.deleted) {
          currentHierarchy = style.hierarchy
          twoStyles += `${style.name} `
        } else if (style.hierarchy === 3 && !style.deleted) {
          currentHierarchy = style.hierarchy
          threeStyles += `${style.name} `
        } else if (style.hierarchy === 4 && !style.deleted) {
          currentHierarchy = style.hierarchy
          fourStyles += `${style.name} `
        }
      })
      switch (currentHierarchy) {
        case 0:
          object.rules = mainStyles
          break;
        case 1:
          object.rules = mainStyles + oneStyles
          break;
        case 2:
          object.rules = mainStyles + oneStyles + '\n' + twoStyles
          break;
        case 3:
          object.rules = mainStyles + oneStyles + '\n' + twoStyles + '\n' + threeStyles
          break;
        case 4:
          object.rules = mainStyles + oneStyles + '\n' + twoStyles + '\n' + threeStyles + '\n' + fourStyles
          break;
      }

      // 組首圖
      object.merchandiseInfo.merchandisePictureLinks.forEach(img => {
        if (img.front) object.imgUrl = handleImageHash2Url(img.imageHash)
      })

      // 描述與款式規格合併
      if (object.rules && this.postToLineNotify.showType === 'hasDescription') object.description += object.rules
      
      if (object.placeOnOrderUrlPosition === 'bottom') object.description += `\n下單連結: ${placeOnOrderUrl}`

      finalObj = object
      
      console.log(finalObj)
      return finalObj
    },
    // 更換下單連結位置
    changePlaceOnOrderUrlPosition(data, index) {
      let object = {
        id: data.id,
        name: data.name,
        description: '',
        rules: '',
        imgUrl: '',
        placeOnOrderUrlPosition: data.placeOnOrderUrlPosition === 'd4' ? 'bottom' : 'd4', // d4: 預設位置，bottom: 在最底下
        merchandiseInfo: data.merchandiseInfo,
      }
      let finalObj = this.combineMerchInfo(object)
      this.postToLineNotify.finalMessages[index] = finalObj
    },
    // 發佈到 line notify
    postToNotify() {
      if (this.postToLineNotify.selectNotify.length === 0) return this.SweetAlert('other', '您尚未選擇發送對象')
      this.$methods.switchLoading('show')
      let messagesArray = []
      this.postToLineNotify.finalMessages.forEach(item => {
        let message = '\n' + item.description + '\n'
        if (this.postToLineNotify.setOwnMessage) message += `賣家的話:\n${this.postToLineNotify.setOwnMessage}`
        const splitResults = this.splitGraph(message, 800)
        console.log(splitResults)
        splitResults.forEach((paragraph, index) => {
          if (paragraph.replace(/\n/gm, "")) {
            const object = {
              finalParagraph: '',
              imgUrl: null
            }
            let finalParagraph = ''
            // 因為系統有預設文字，所以文章有被切割的話，除了第一個段落不用加 \n 以外其餘都要加
            if (index > 0) finalParagraph = `\n${paragraph}`
            else finalParagraph = paragraph
            object.finalParagraph = finalParagraph
            messagesArray.push(object)
          }
        })
        // 假設文章被切割成三段而且他又有圖片的話，圖片要照著被切三段的段落依序 (null, null, 圖片)
        const lastIndex = messagesArray.length - 1
        if (lastIndex >= 0 && item.imgUrl) messagesArray[lastIndex].imgUrl = item.imgUrl
      })
      // 組發送對象
      let jsonArray = []
      console.log(messagesArray)
      this.postToLineNotify.selectNotify.forEach(item => {
        messagesArray.forEach(msgRes => {
          console.log(msgRes)
          if (item === 'mySelf') {
            jsonArray.push({
              type: 13,
              id: this.CurrentParticipant.data.id,
              message: msgRes.finalParagraph,
              imgUrl: msgRes.imgUrl
            }) 
          } else {
            jsonArray.push({
              type: 41,
              id: item,
              message: msgRes.finalParagraph,
              imgUrl: msgRes.imgUrl
            })
          }
        })
      })
      console.log(jsonArray)
      const data = new FormData()
      data.append('jsonString', JSON.stringify(jsonArray))
      this.$api.line.notify(data).then(res => {
        console.log(res);
        if (res.code === '200') {
          this.SweetAlert(res.code, res.message)
          this.postToLineNotifyModal.hide()
          this.$emit('notifyParentFromPostToLineNotify', {
            showLineNotifyModal: false,
            clearSelectItems: true
          })
        } else {
          this.SweetAlert(res.code, res.message)
        }
        this.$methods.switchLoading('hide')
      }).catch(err => {
        console.log(err)
        this.SweetAlert(err.responseJSON.code, err.responseJSON.message)
        this.$methods.switchLoading('hide')
      })
    },
  }
}
</script>