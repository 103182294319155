<!-- 至 Line 待推播列表 modal -->
<template>
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="toLinePushModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">
            至 Line 待推播列表
            <a href="https://youtu.be/xtK_quJZI0g?si=6nr_BiKTdhpxhH1v" target="_blank">
              <img
                class="info-icon"
                src="@/assets/icon/interrogation.png"
              />
            </a>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="closeModal"
          ></button>
        </div>
        <div class="modal-body">
          <SelectInfos :propsSelectInfos="propsSelectInfos"></SelectInfos>
          <p class="alert alert-danger mb-3">
            貼心提醒：選擇重複的商品我們只會推播一次
          </p>
          <div>
            <!-- 設定預設文字內容 -->
            <ul class="d4Line-push-text mb-3">
              <!-- title -->
              <li class="row mb-2 fw-bolder text-center p-2">
                <div class="col-5">商品名稱</div>
                <div class="col-7">
                  推播文字
                  <input
                    type="text"
                    class="d-inline-block w-75"
                    maxlength="10"
                    v-model="linePushData.globalText"
                  />
                </div>
              </li>
              <!-- content -->
              <li class="row" v-for="data in merchandises" :key="data.id">
                <div class="col-5 text-break d-flex align-items-center">
                  {{ data.name }}
                </div>
                <div class="col-7">
                  <input
                    type="text"
                    class="form-control d-inline-block"
                    id="btnText"
                    :placeholder="data.displayText"
                    v-model="data.displayText"
                    maxlength="10"
                  />
                </div>
              </li>
            </ul>
            <!-- 插播至最優先 -->
            <div class="row align-items-center mb-3">
              <div class="col-lg-3">
                <input
                  class="me-2"
                  type="checkbox"
                  name="linePush"
                  id="jump"
                  value="jump"
                  v-model="linePushData.jump"
                />
                <label for="jump" class="mb-2">插播至最優先</label>
              </div>
            </div>
            <!-- 選擇 Line 群組 -->
            <div>
              <h5 class="tw-border-start fw-bolder mb-3">
                選擇 Line 群組
                <template class="ms-1" v-if="!lineBotData.loading">
                  <button
                    class="tw-btn tw-btn-danger"
                    v-if="
                      linePushData.storeLineGroupLinkIds.length ===
                      lineBotData.data.length
                    "
                    @click="allCheck('toLinePush')"
                  >
                    取消全選
                  </button>
                  <button
                    v-else
                    class="tw-btn tw-btn-success"
                    @click="allCheck('toLinePush')"
                  >
                    全選
                  </button>
                </template>
              </h5>
              <AreaLoading v-if="lineBotData.loading"></AreaLoading>
              <div v-else class="ms-3">
                <template v-if="lineBotData.data.length > 0">
                  <div
                    class="mb-2"
                    v-for="data in lineBotData.data"
                    :key="data.linkId"
                  >
                    <input
                      type="checkbox"
                      class="me-2"
                      :value="data.linkId"
                      :id="data.linkId"
                      name="selectLineGroup"
                      v-model="linePushData.storeLineGroupLinkIds"
                    />
                    <label :for="data.linkId">{{ data.groupName }}</label>
                  </div>
                </template>
                <p v-else class="text-danger">目前尚未有任何群組!</p>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
            @click="closeModal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="toLinePush"
            :disabled="lineBotData.data.length === 0"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    lineBotData: {
      type: Object,
      default: {
        loading: false,
        data: []
      }
    },
    propsSelectInfos: {
      type: Object,
      default: {
        show: 'id',
        data: []
      }
    },
    showLinePushModal:{
      type: Boolean,
      default: false
    },
    defaultText: {
      type: String,
      default: '去瞧瞧 >>'
    },
  },
  emits: ['notifyParentFromLinePush'],
  data() {
    return {
      // modal
      toLinePushModal: {},
      // 至 Line 待推播列表
      toLinePushLineGroupAllCheck: false,
      linePushData: {
        jump: false,
        storeLineGroupLinkIds: [],
        globalText: '',
      },
      merchandises: [],
    }
  },
  watch: {
    "showLinePushModal": {
      handler(val, oldVal) {
        if (val) {
          this.linePushData= {
            jump: false,
            storeLineGroupLinkIds: [],
            globalText: this.defaultText,
          },
          this.merchandises = []
          this.propsSelectInfos.data.forEach(item => {
            item.displayText = this.defaultText
          })
          this.merchandises = this.propsSelectInfos.data
          this.toLinePushModal.show()
        }
      }
    },
    // 批次修改推播文字
    "linePushData.globalText": {
      handler(val) {
        this.propsSelectInfos.data.forEach(item => {
          item.displayText = val
        })
      },
    }
  },
  mounted() {
    this.createModals(['toLinePushModal'])
  },
  methods: {
    // 至 Line 待推播列表
    toLinePush() {
      this.$methods.switchLoading('show')
      if (this.linePushData.storeLineGroupLinkIds.length === 0) {
        this.$methods.switchLoading('hide')
        return this.SweetAlert('other', '請至少選擇一個群組')
      }
      const data = []
      this.linePushData.storeLineGroupLinkIds.forEach(groupLinkId => {
        this.merchandises.forEach(merchandise => {
          data.push({
            merchandiseId: merchandise.id,
            groupLinkId: groupLinkId,
            displayText: merchandise.displayText,
            jump: this.linePushData.jump
          })
        })
      })
      this.$api.line.addMerchandiseToLinePushQueue(data).then(res => {
        console.log(res)
        if (res.code === '200') {
          this.SweetAlert(res.code, res.message)
          this.toLinePushModal.hide()
          this.$emit('notifyParentFromLinePush', {
            showLinePushModal: false,
            clearSelectItems: true
          })
        } else {
          this.SweetAlert(res.code, res.message)
        }
        this.$methods.switchLoading('hide')
      }).catch(err => {
        console.log(err)
        this.SweetAlert(err.responseJSON.code, err.responseJSON.message)
        this.$methods.switchLoading('hide')
      })
    },
    // 全選 
    allCheck() {
      if (this.linePushData.storeLineGroupLinkIds.length !== this.lineBotData.data.length) this.toLinePushLineGroupAllCheck = false
      else this.toLinePushLineGroupAllCheck = true
      if (this.toLinePushLineGroupAllCheck) {
        this.toLinePushLineGroupAllCheck = false
        this.linePushData.storeLineGroupLinkIds = []
      } else {
        this.toLinePushLineGroupAllCheck = true
        const storeLineGroupLinkIds = []
        this.lineBotData.data.forEach((item) => storeLineGroupLinkIds.push(item.linkId))
        this.linePushData.storeLineGroupLinkIds = storeLineGroupLinkIds
      }
    },
    // 關閉 modal
    closeModal() {
      this.$emit('notifyParentFromLinePush', {
        showLinePushModal: false,
        clearSelectItems: false
      })
    },
  }

  
}
</script>

<style lang="scss" scoped>
.d4Line-push-text {
  padding: 5px 0;
  background-color: rgb(254, 255, 197);
  li {
    margin-bottom: 5px;
    border-bottom: 1px solid black;
    &:hover {
      background-color: lightblue;
    }
  }
}
</style>